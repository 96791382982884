import * as Locales from 'date-fns/locale';
import * as Localization from 'expo-localization';

import { Asset, AssetWithMimeType, Config, SectionConfig, Show } from '../schema';
import { ComposedShow, ComposedStream, Orientation, ShowState } from '@hello-lisa/schemas';
import {
  createInitials,
  dateFromDateTime,
  getCurrentShowStream,
  getShowState,
  resolveTeaserMarkup,
} from '@hello-lisa/utils';

import { Pointer } from 'rfc6902/pointer';
import format from 'date-fns/format';
import { marked } from 'marked';
import { resolveLocalizedValue } from './utilities';

/**
 *
 * @param source - The source to create show data from.
 * @param section - The section containing the show.
 * @param config - The LiSA library config.
 */
export const fromComposedShow = (
  source: ComposedShow,
  config: Config,
  section?: SectionConfig,
): Show => {
  const state = getShowState(source);

  const assetOrientation =
    section?.layout?.assetOrientation ?? config.layout?.assetOrientation ?? 'portrait';

  const asset = getAsset(source, state, config, assetOrientation);
  const date = getDate(source, config);
  const description = getDescription(source, state, config);
  const title = getTitle(source, state, config);

  const variations = [assetOrientation];

  return {
    asset,
    date,
    description,
    state,
    title,
    variations,
    shortClips: config.shortClips ? getShortClips(source) : undefined,
    hosts: (source.hosts ?? []).map((host) => ({
      ...host,
      initials: createInitials(host.displayName),
    })),
    id: source.id,
    tag: section?.tag,
  };
};

/**
 *
 * @param source - The source to read the asset from.
 * @param key - The asset key.
 * @param state - The show state.
 */
export const findAsset = (
  source: ComposedShow,
  key: 'coverLandscape' | 'coverPortrait',
  state: ShowState,
): Asset | undefined => {
  const paths: string[] = [];

  if (state === 'live' || state === 'replay') {
    paths.push(`/views/${state}/assets/${key}/publicUrl`);
  }

  paths.push(
    ...[
      `/views/preShow/assets/${key}/publicUrl`,
      `/assets/${key}/publicUrl`,
      `/settings/general/views/default/assets/${key}/publicUrl`,
    ],
  );

  return findAssetInPaths(source, paths);
};

export const getShortClips = (source: ComposedShow): AssetWithMimeType[] => {
  const stream = getCurrentShowStream(source) as ComposedStream | undefined;
  if (stream === undefined) {
    return [];
  }

  return (stream.shortClips || [])
    .filter((_) => _.orientation === 'portrait')
    .map((_) => ({
      orientation: _.orientation,
      id: _.id,
      mime: mimeFromFile(_.url),
      type: 'video',
      url: _.url,
    }));
};

/**
 *
 * @param url - The URL to derive the mime-type from.
 */
export const mimeFromFile = (url: string): string => {
  if (url.endsWith('.m3u8')) {
    return 'application/x-mpegURL';
  }
  return 'video/mp4';
};

export const findAssetInPaths = (source: ComposedShow, paths: string[]): Asset | undefined => {
  while (paths.length > 0) {
    const path = paths.shift();
    if (path !== undefined) {
      const pointer = Pointer.fromJSON(path);
      const url = pointer.get(source) as string | undefined;
      if (url !== undefined) {
        return {
          orientation: path.endsWith('Landscape/publicUrl') ? 'landscape' : 'portrait',
          type: 'image',
          url,
        };
      }
    }
  }
  return undefined;
};

export const getAsset = (
  source: ComposedShow,
  state: ShowState,
  config: Config,
  orientation: Orientation,
): Asset | undefined => {
  const key = orientation === 'portrait' ? 'Portrait' : 'Landscape';

  let asset = findAssetInPaths(source, [`/assets/promotion${key}/publicUrl`]);
  if (asset === undefined) {
    asset = findAsset(source, `cover${key}`, state);
  }

  const modifier = orientation === 'portrait' ? '480x' : 'x854';
  if (asset !== undefined) {
    asset.url = asset?.url.replace(/\.(jpe?g|png|webp)$/, `.${modifier}.$1`);
  }

  if (typeof config.data?.asset === 'function') {
    return config.data.asset(source, asset);
  }
  return asset;
};

export function getDateFnsLocale(input: Pick<typeof Localization, 'locale' | 'region'>): Locale {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-call,@typescript-eslint/restrict-template-expressions
  const locale = `${input.locale.substring(0, 2)}${input.region ?? ''}`;

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return Locales[locale] ?? Locales[locale.substring(0, 2)] ?? Locales.enUS;
}

/**
 *
 * @param source - The source to read the date from.
 * @param config - The LiSA library config.
 */
export const getDate = (source: ComposedShow, config: Config): string | undefined => {
  const date = dateFromDateTime(source.date);
  const localeValue = config.locale || 'en-US';
  const locale = getDateFnsLocale({
    locale: localeValue.substring(0, 2),
    region: localeValue.substring(3, 2),
  });

  switch (typeof config.data?.date) {
    case 'function':
      return config.data.date(source, date, locale);
    case 'string':
      return format(date, config.data.date, { locale });
  }
  return format(date, 'MMMM d, h:mm aa');
};

/**
 *
 * @param source - The source to read the description value from.
 * @param state - The show state.
 * @param config - The LiSA library config.
 */
export const getDescription = (
  source: ComposedShow,
  state: ShowState,
  config: Config,
): string | undefined => {
  let description = resolveLocalizedValue(source.description, config.language);
  if (description === undefined) {
    description = resolveLocalizedValue(source.views?.[state]?.teaser, config.language);
  }
  if (description === undefined && state !== 'preShow') {
    description = resolveLocalizedValue(source.views?.preShow?.teaser, config.language);
  }
  if (description !== undefined) {
    description = resolveTeaserMarkup(description, source.hosts);
    return typeof config.data?.description === 'function'
      ? config.data.description(source, description)
      : marked(description);
  }
  return undefined;
};

/**
 *
 * @param source - The source to read the title value from.
 * @param state - The show state.
 * @param config - The LiSA library config.
 */
export const getTitle = (
  source: ComposedShow,
  state: ShowState,
  config: Config,
): string | undefined => {
  let title = resolveLocalizedValue(source.views?.[state]?.title, config.language);
  if (title === undefined && state !== 'preShow') {
    title = resolveLocalizedValue(source.views?.preShow?.title, config.language);
  }
  if (title === undefined) {
    title = resolveLocalizedValue(source.title, config.language);
  }
  if (title === undefined) {
    return undefined;
  }
  return typeof config.data?.title === 'function' ? config.data.title(source, title) : title;
};
