import videojs, { VideoJsPlayer, VideoJsPlayerOptions } from 'video.js';

import EventEmitter from 'events';
import StrictEventEmitter from 'strict-event-emitter-types';

export enum ShortClipPlayerEvent {
  END = 'short-clip.end',
  FINAL = 'short-clip.final',
  PAUSE = 'short-clip.pause',
  RESUME = 'short-clip.resume',
  START = 'short-clip.start',
}

export enum Selectors {
  ACTIVE = 'lisa__short-clip--active',
  READY = 'lisa__show-media-video--ready',
}

interface ShortClipPlayerEventEmitter {
  new (): StrictEventEmitter<EventEmitter, ShortClipPlayerEvents>;
}

interface ShortClipPlayerEvents {
  [ShortClipPlayerEvent.END]: (position: number) => void;
  [ShortClipPlayerEvent.FINAL]: (position: number) => void;
  [ShortClipPlayerEvent.PAUSE]: (position: number) => void;
  [ShortClipPlayerEvent.RESUME]: (position: number) => void;
  [ShortClipPlayerEvent.START]: (position: number) => void;
}

export class ShortClipPlayer extends (EventEmitter as ShortClipPlayerEventEmitter) {
  public readonly Event = ShortClipPlayerEvent;

  private player?: VideoJsPlayer;

  private active = false;
  private ready = false;
  private started = false;
  private stopped = true;

  constructor(
    public readonly element: HTMLVideoElement,
    public readonly position: number,
    private readonly loop: boolean,
  ) {
    super();
  }

  init(): void {
    if (this.player !== undefined) {
      return;
    }

    const options: VideoJsPlayerOptions = {
      children: ['MediaLoader'],
      controls: false,
      loop: this.loop,
      muted: true,
      nativeControlsForTouch: false,
    };
    this.player = videojs(this.element, options, () => this.registerEventHandlers());
  }

  isPlaying(): boolean {
    return this.player?.paused() === false;
  }

  isReady(): boolean {
    return this.ready;
  }

  fadeOut(): void {
    this.active = false;
    this.player?.el()?.classList.remove(Selectors.ACTIVE);
    this.player?.pause();
    this.stopped = true;
  }

  fadeIn(): void {
    this.stopped = false;

    this.player?.currentTime(0);
    this.player?.play()?.catch((_) => {
      // silent fail
    });

    this.active = true;
    this.player?.el()?.classList.add(Selectors.ACTIVE);
  }

  pause(): void {
    this.player?.pause();
    this.stopped = true;
    this.emit(ShortClipPlayerEvent.PAUSE, this.position);
    this.player?.el()?.classList.remove(Selectors.ACTIVE);
  }

  play(): void {
    this.stopped = false;

    this.player?.currentTime(0);
    this.player?.play()?.catch((_) => {
      // silent fail
    });
  }

  resume(): void {
    this.player?.play()?.catch((_) => {
      // silent fail
    });
    this.stopped = false;
    this.emit(ShortClipPlayerEvent.RESUME, this.position);
    this.player?.el()?.classList.add(Selectors.ACTIVE);
  }

  registerEventHandlers(): void {
    this.player?.on('loadeddata', () =>
      this.player?.el()?.parentElement?.classList.add(Selectors.READY),
    );

    this.player?.on('timeupdate', () => {
      if (!this.started && (this.player?.currentTime() ?? 0) > 0) {
        this.started = true;
        this.emit(ShortClipPlayerEvent.START, this.position);
      }

      if (
        this.player?.loop() !== true &&
        !this.stopped &&
        (this.player?.remainingTime() ?? 0) < 2
      ) {
        this.stopped = true;
        this.emit(ShortClipPlayerEvent.FINAL, this.position);
      }
    });

    this.player?.on('ended', () => {
      if (this.player?.loop() !== true) {
        this.player?.currentTime(0);
        this.started = false;
        this.stopped = true;
        if (this.active) {
          this.active = false;
          this.emit(ShortClipPlayerEvent.END, this.position);
        }
      }
    });
  }
}
