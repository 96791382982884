import { Section } from '../section';
import { Slider } from './slider';

export const initialize = (section: Section): Slider | undefined => {
  const element = section
    .getElement()
    .querySelector<HTMLElement>('.lisa__section--slider .lisa__section-listing');

  if (!element) {
    return;
  }
  return new Slider(element, { selector: '.lisa__show' });
};
