import { LanguageCode, LocalizedString } from '@hello-lisa/schemas';
import { dateFromDateTime, getShowState } from '@hello-lisa/utils';

import { Options } from 'ky';

export { dateFromDateTime, getShowState };

export const closestAncestor = (
  element: HTMLElement | null,
  test: (element: HTMLElement | null) => boolean,
): HTMLElement | null => {
  element = element?.parentElement ?? null;
  if (element === null) {
    return null;
  }
  return test(element) ? element : closestAncestor(element, test);
};

/**
 *
 * @param client - The client identifier.
 */
export const createRequestOptions = (client: string): Options => {
  // headers
  const headers = new Headers();
  headers.append('Authorization', `Client ${client}`);
  headers.append('X-LiSA-Client', client);
  // timeout
  const timeout = 5000;
  return { headers, timeout };
};

/**
 * Receives a query selector and attempts to find a matching HTML element.
 *
 * @param selector - The element query selector.
 */
export const findElement = (selector: string): HTMLElement | null => {
  const hostNode = document.querySelector<HTMLElement>(selector);
  if (hostNode === null) {
    console.warn('Host node not found. Unable to inject LiSA markup!');
    return null;
  }
  return hostNode;
};

export const getSafeArea = (direction: 't' | 'r' | 'b' | 'l'): number => {
  const computed = window
    .getComputedStyle(document.documentElement)
    .getPropertyValue(`--lisa-library--sa-${direction}`);
  const parsed = parseInt(computed.replace('px', ''));
  return isNaN(parsed) ? 0 : parsed;
};

/**
 * Tells whether the given value is a string
 *
 * @param value - The value to test.
 */
export const isStringValue = (value: unknown): value is string => {
  return value !== null && value !== undefined && typeof value === 'string';
};

/**
 * Takes an array of query selectors and attempts to remove the target elements
 * from the given document.
 *
 * @param selectors -
 * @param document -
 */
export const removeElements = (selectors: string[], document?: Document | null): string[] => {
  const notFound: string[] = [];
  selectors.forEach((selector) => {
    const el = document?.querySelector(selector);
    if (el === undefined || el === null) {
      notFound.push(selector);
    } else {
      el?.remove();
    }
  });
  return notFound;
};

/**
 * Resolves a localized value to a desired language.
 *
 * @param value - The localized value.
 * @param language - Optional. The language code to use.
 */
export const resolveLocalizedValue = (
  value?: LocalizedString,
  language?: LanguageCode,
): string | undefined => {
  if (value === undefined) {
    return undefined;
  }

  if (language !== undefined && language !== '*' && value[language] !== undefined) {
    return value[language];
  }

  return value['*'];
};

export const stickToViewport = (selector: string): void => {
  const node = document.querySelector<HTMLElement>(selector);
  if (node === null) {
    console.warn(`Unable to stick element to viewport. No element found using: ${selector}`);
    return;
  }

  const adjustStageSize = (): void => {
    const viewportHeight = window.innerHeight;
    node.style.height = `${viewportHeight}px`;
  };

  window.addEventListener('resize', adjustStageSize);

  adjustStageSize();
};

/**
 *
 * @param value -
 */
export const toKebabCase = (value: string): string => {
  return value.replace(
    /([A-Z])/g,
    (_match: string, ...groups: [string, number, string]) => `-${groups?.[0]?.toLowerCase()}`,
  );
};
