import * as Utils from '../utilities';

import { ComposedShow, ScrollableResourceList } from '@hello-lisa/schemas';

import { config } from '../../config';
import ky from 'ky';

export const getShow = async (client: string, id: string): Promise<ComposedShow | undefined> => {
  const url = `${config.showsUrl}/${id}`;
  return ky
    .get(url, Utils.createRequestOptions(client))
    .json<ComposedShow>()
    .catch((err) => {
      console.error(err);
      return undefined;
    });
};

export const getShows = async (
  client: string,
  channel?: string,
): Promise<ScrollableResourceList<ComposedShow>> => {
  let url = `${config.showsUrl}`;
  if (channel !== undefined) {
    url = `${url}/channels/${channel}`;
  }

  return ky
    .get(url, Utils.createRequestOptions(client))
    .json<ScrollableResourceList<ComposedShow>>()
    .catch((err) => {
      console.error(err);
      return { items: [], scrolling: { count: 0, total: 0 } };
    });
};
