import { Indicator, Utils } from '.';

import { EventEmitter } from 'events';
import { Library } from './library';
import { QuickViewConfig } from '../schema';
import StrictEventEmitter from 'strict-event-emitter-types';
import { logMessage } from '@hello-lisa/lib-media-player';

enum Class {
  CLOSE = 'lisa__close',
  CLOSE_ACTIVE = 'lisa__close--active',
  CLOSE_ANIMATED = 'lisa__close--animated',
  CLOSE_CLOSING = 'lisa__close--closing',
  CLOSE_HIDDEN = 'lisa__close--hidden',
  CLOSE_INACTIVE = 'lisa__close--inactive',
  DIALOG = 'lisa__quick-view-dialog',
  DIALOG_ANIMATED = 'lisa__quick-view-dialog--animated',
  DIALOG_CLOSING = 'lisa__quick-view-dialog--closing',
  DIALOG_HIDDEN = 'lisa__quick-view-dialog--hidden',
  FRAME = 'lisa__quick-view-frame',
  FRAME_HIDDEN = 'lisa__quick-view-frame--hidden',
  INDICATOR_HIDDEN = 'lisa__indicator--hidden',
  OPEN = 'lisa__quick-view--open',
}

export enum QuickViewEvent {
  CLOSE = 'quick-view.close',
  CLOSED = 'quick-view.closed',
  OPEN = 'quick-view.open',
  OPENED = 'quick-view.opened',
}

interface QuickViewEvents {
  [QuickViewEvent.CLOSE]: void;
  [QuickViewEvent.CLOSED]: void;
  [QuickViewEvent.OPEN]: void;
  [QuickViewEvent.OPENED]: void;
}

type QuickViewEventEmitter = StrictEventEmitter<EventEmitter, QuickViewEvents>;

export class QuickView extends (EventEmitter as { new (): QuickViewEventEmitter }) {
  private readonly config?: QuickViewConfig;
  public readonly Event = QuickViewEvent;
  public static readonly Event = QuickViewEvent;

  private closeNode?: HTMLButtonElement;
  private dialogNode?: HTMLDivElement;
  private frameNode?: HTMLIFrameElement;
  private indicatorNode?: HTMLDivElement;

  public open = false;

  constructor(public readonly library: Library) {
    super();

    this.config = this.library.config.quickView;
  }

  private getFrameNode(): HTMLIFrameElement {
    if (this.frameNode === undefined) {
      const frame = document.createElement('iframe');
      frame.classList.add(Class.FRAME, Class.FRAME_HIDDEN);
      frame.setAttribute('allow', 'web-share');
      frame.setAttribute('src', 'about:blank');

      logMessage('info', 'Create QuickView modal');

      frame.addEventListener('load', () => {
        logMessage('info', 'Got QuickView modal load event');

        if (frame.src === 'about:blank') {
          logMessage(
            'info',
            'QuickView modal onload exit early',
            frame.src === 'about:blank',
            frame.contentDocument === null,
          );
          return;
        }

        const widgetUrl = new URL(window.location.href);
        const frameUrl = new URL(frame.src);

        const isSameOrigin =
          widgetUrl.protocol === frameUrl.protocol && widgetUrl.hostname === frameUrl.hostname;

        if (!isSameOrigin) {
          frame.classList.remove(Class.FRAME_HIDDEN);
          this.indicatorNode?.classList.add(Class.INDICATOR_HIDDEN);
          return;
        }

        const doc = frame.contentDocument;
        if (doc === null) {
          return;
        }

        // apply element removal
        let selectors = this.config?.cleanUp || [];
        const interval = window.setInterval(() => {
          if (selectors.length > 0) {
            selectors = Utils.removeElements(selectors, doc);
          }
        }, 50);

        logMessage('info', 'QuickView modal doc readyState', doc.readyState);

        if (doc.readyState === 'complete') {
          frame.classList.remove(Class.FRAME_HIDDEN);
          this.indicatorNode?.classList.add(Class.INDICATOR_HIDDEN);
        } else {
          doc.addEventListener('readystatechange', () => {
            logMessage('info', 'QuickView modal got readystatechange event', frame.src);

            if (frame.src === 'about:blank') {
              return;
            }

            if (doc.readyState === 'complete') {
              frame.classList.remove(Class.FRAME_HIDDEN);
              this.indicatorNode?.classList.add(Class.INDICATOR_HIDDEN);
            }
          });
        }

        selectors = Utils.removeElements(selectors, frame.contentDocument);
        window.clearInterval(interval);
      });

      this.frameNode = frame;
    }
    return this.frameNode;
  }

  /**
   * Accessor to the quick view's close HTML element.
   *
   * It will create the HTML element upon first invocation.
   */
  private getCloseNode(): HTMLButtonElement {
    if (this.closeNode === undefined) {
      const close = document.createElement('button');
      close.classList.add(
        Class.CLOSE,
        Class.CLOSE_ANIMATED,
        Class.CLOSE_HIDDEN,
        Class.CLOSE_INACTIVE,
      );

      close.addEventListener('click', (event: MouseEvent) => {
        event.preventDefault();
        event.stopPropagation();

        this.hide();
      });

      close.addEventListener('touchstart', (event) => {
        event.preventDefault();

        close.classList.remove(Class.CLOSE_ANIMATED);
        this.getDialogNode().classList.remove(Class.DIALOG_ANIMATED);

        close.dataset.drag = 'enabled';
        close.dataset.dragStart = `${new Date().getTime()}`;
        close.dataset.dragOffset = `${event.changedTouches[0]?.clientY ?? 0}`;
      });

      close.addEventListener('touchmove', (event) => {
        event.preventDefault();

        const previous = parseFloat(close.dataset.dragOffset ?? '0');
        const current = event.changedTouches[0]?.clientY ?? 0;
        const gap = current - previous;

        close.style.transform = `translateY(${gap}px)`;

        if (gap < 0) {
          this.getDialogNode().style.height = `calc(100% - 100px - ${gap}px)`;
        } else {
          this.getDialogNode().style.transform = `translateY(${gap}px)`;
        }
      });

      close.addEventListener('touchend', (event) => {
        event.preventDefault();

        close.classList.add(Class.CLOSE_ANIMATED);
        this.getDialogNode().classList.add(Class.DIALOG_ANIMATED);

        const previous = parseFloat(close.dataset.dragOffset ?? '0');
        const current = event.changedTouches[0]?.clientY ?? 0;
        let gap = current - previous;

        const start = parseInt(close.dataset.dragStart ?? '0');
        const end = new Date().getTime();
        const speed = end - start;

        if (gap < 0) {
          this.getDialogNode().style.height = 'calc(100% - 100px)';
          gap = 0;
        }

        let translate = '0';
        if (current / previous > 2 || (gap > 48 && speed < 250)) {
          translate = 'calc(100vh - 192px)';

          window.setTimeout(this.hide.bind(this), Library.ANIMATION_DURATION);
        }

        close.style.transform = `translateY(${translate})`;
        this.getDialogNode().style.transform = `translateY(${translate})`;

        delete close.dataset.drag;
        delete close.dataset.dragOffset;
        delete close.dataset.dragStart;
      });

      this.closeNode = close;
      document.body.appendChild(this.closeNode);
    }
    return this.closeNode;
  }

  /**
   * Accessor to the quick view's dialog HTML element.
   *
   * It will create the HTML element upon first invocation.
   */
  private getDialogNode(): HTMLDivElement {
    if (this.dialogNode === undefined) {
      this.dialogNode = document.createElement('div');
      this.dialogNode.classList.add(Class.DIALOG, Class.DIALOG_ANIMATED);

      // create and append frame
      this.frameNode = this.getFrameNode();
      this.dialogNode.appendChild(this.frameNode);

      // create and append indicator
      this.indicatorNode = Indicator.create();
      this.dialogNode.appendChild(this.indicatorNode);

      document.body.appendChild(this.dialogNode);
    }
    return this.dialogNode;
  }

  destroy(): void {
    this.closeNode?.remove();
    this.dialogNode?.remove();
    this.frameNode?.remove();
    this.indicatorNode?.remove();

    this.closeNode = undefined;
    this.dialogNode = undefined;
    this.dialogNode = undefined;
    this.indicatorNode = undefined;
  }

  hide(): void {
    document.body.classList.remove(Class.OPEN);
    this.emit(QuickViewEvent.CLOSE);
    this.open = false;

    const close = this.getCloseNode();
    close.classList.add(Class.CLOSE_CLOSING);

    const dialog = this.getDialogNode();
    dialog.classList.add(Class.DIALOG_CLOSING);

    window.setTimeout(() => {
      close.classList.add(Class.CLOSE_HIDDEN);
      close.classList.remove(Class.CLOSE_CLOSING);
      close.style.transform = 'unset';

      dialog.classList.add(Class.DIALOG_HIDDEN);
      dialog.classList.remove(Class.DIALOG_CLOSING);
      dialog.style.transform = 'unset';
      dialog.style.height = `calc(100% - ${this.library.isMobile ? 100 : 120}px)`;

      const frame = this.getFrameNode();
      frame.src = 'about:blank';
      frame.classList.add(Class.FRAME_HIDDEN);

      this.indicatorNode?.classList.add(Class.INDICATOR_HIDDEN);

      this.emit(QuickViewEvent.CLOSED);
    }, Library.ANIMATION_DURATION);
  }

  show(url: string): void {
    document.body.classList.add(Class.OPEN);
    this.emit(QuickViewEvent.OPEN);

    this.dialogNode = this.getDialogNode();
    this.dialogNode.classList.remove(Class.DIALOG_HIDDEN);

    window.setTimeout(() => {
      this.closeNode = this.getCloseNode();
      this.closeNode.classList.remove(Class.CLOSE_HIDDEN);
    }, Library.ANIMATION_DURATION);

    this.indicatorNode?.classList.remove(Class.INDICATOR_HIDDEN);

    this.frameNode = this.getFrameNode();
    this.frameNode.src = url;

    this.open = true;
    this.emit(QuickViewEvent.OPENED);
  }
}
