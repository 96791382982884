import { Dimension } from '../../schema';
import { EventEmitter } from 'events';
import StrictEventEmitter from 'strict-event-emitter-types';

export enum Selectors {
  SLIDE = 'lisa-hub__slider-slide',
}

export enum SlideEvent {
  MOVE = 'slider.arrows.move',
}

interface SlideEventEmitter {
  new (): StrictEventEmitter<EventEmitter, SlideEvents>;
}

interface SlideEvents {
  [SlideEvent.MOVE]: (direction: 'left' | 'right') => void;
}

export class Slide extends (EventEmitter as SlideEventEmitter) implements Dimension {
  public static readonly Event = SlideEvent;

  public height = 0;
  public offset = 0;
  public width = 0;

  constructor(private readonly element: HTMLElement) {
    super();

    const style = window.getComputedStyle(this.element);

    this.height = parseInt(style.height) + parseInt(style.marginTop) + parseInt(style.marginBottom);
    this.width = parseInt(style.width) + parseInt(style.marginLeft) + parseInt(style.marginRight);
  }
}
