export interface Config {
  feedUrl: string;
  settingsUrl: string;
  showsUrl: string;
}

const baseUrl = 'https://proxy.lisa-api.net/api';

export const config: Config = {
  feedUrl: 'https://feed.lisa-api.net',
  settingsUrl: `${baseUrl}/clients/settings`,
  showsUrl: `${baseUrl}/shows`,
};
