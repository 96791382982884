import {
  CarouselItemBannerEventListener,
  CarouselItemInput,
  CarouselItemProductEventListener,
  CartEventListener,
  CtaEventListener,
  Messaging,
  ShowEventListener,
  UserJoinInput,
} from '@hello-lisa/messaging';
import { ComposedShow, Orientation } from '@hello-lisa/schemas';
import { ElementPosition, PlayerConfig } from '../schema';
import { UUID, uuid } from '@hello-lisa/uuid';

import { Backdrop } from './backdrop';
import { EventEmitter } from 'events';
import { Library } from './library';
import { MediaPlayerOptions } from '@hello-lisa/lib-media-player';
import StrictEventEmitter from 'strict-event-emitter-types';

export enum PlayerEvent {
  BANNER_VIEW = 'carousel-item.view.banner',
  CART_VIEW = 'cart.view',
  CTA_CLICK = 'cta.click',
  CLOSE = 'close',
  ERROR = 'error',
  OPEN = 'open',
  READY = 'player.ready',
  MAXIMIZED = 'player.maximized',
  MINIMIZED = 'player.minimized',
  PRODUCT_ADD_TO_CART = 'carousel-item.add-to-cart.product',
  PRODUCT_GRANT_REACTION = 'carousel-item.grant-reaction.product',
  PRODUCT_REVOKE_REACTION = 'carousel-item.revoke-reaction.product',
  PRODUCT_VIEW = 'carousel-item.view.product',
  SHOW_READY = 'show.ready',
  SHOW_START = 'show.start',
  SHOW_STOP = 'show.stop',
}

interface PlayerEvents {
  [PlayerEvent.BANNER_VIEW]: CarouselItemBannerEventListener;
  [PlayerEvent.CART_VIEW]: CartEventListener;
  [PlayerEvent.CTA_CLICK]: CtaEventListener;
  [PlayerEvent.CLOSE]: void;
  [PlayerEvent.ERROR]: (type: string, message?: string) => void;
  [PlayerEvent.OPEN]: (showId?: UUID) => void;
  [PlayerEvent.READY]: void;
  [PlayerEvent.MAXIMIZED]: void;
  [PlayerEvent.MINIMIZED]: void;
  [PlayerEvent.PRODUCT_ADD_TO_CART]: CarouselItemProductEventListener;
  [PlayerEvent.PRODUCT_VIEW]: CarouselItemProductEventListener;
  [PlayerEvent.PRODUCT_GRANT_REACTION]: CarouselItemProductEventListener;
  [PlayerEvent.PRODUCT_REVOKE_REACTION]: CarouselItemProductEventListener;
  [PlayerEvent.SHOW_READY]: ShowEventListener;
  [PlayerEvent.SHOW_START]: ShowEventListener;
  [PlayerEvent.SHOW_STOP]: ShowEventListener;
}

type PlayerEventEmitter = StrictEventEmitter<EventEmitter, PlayerEvents>;

export class Player extends (EventEmitter as { new (): PlayerEventEmitter }) {
  private readonly element: HTMLDivElement;
  private readonly isOverlay: boolean;
  private readonly messaging: Messaging.MessageHandler;
  private readonly scope: string;

  public readonly Event = PlayerEvent;
  public readonly config?: PlayerConfig;

  private closeButton?: HTMLButtonElement;
  private maximizeButton?: HTMLButtonElement;
  private stage?: HTMLElement;

  private currentShow?: ComposedShow;
  private currentFrame?: HTMLIFrameElement;

  private resizeHandler: () => void;

  public isOpen = false;

  constructor(public readonly library: Library) {
    super();

    this.config = this.library.config.player;
    this.isOverlay = (this.config?.host ?? 'overlay') === 'overlay';
    this.scope = uuid.create();

    this.messaging = Messaging.initialize({ scope: this.scope });
    this.messaging
      .on(this.messaging.Event.BANNER_VIEW, (data, first, banner) =>
        this.emit(PlayerEvent.BANNER_VIEW, data, first, banner),
      )
      .on(this.messaging.Event.CART_VIEW, (url) => this.emit(PlayerEvent.CART_VIEW, url))
      .on(this.messaging.Event.CTA_CLICK, (ref, data) =>
        this.emit(PlayerEvent.CTA_CLICK, ref, data),
      )
      .on(this.messaging.Event.GTM_DATA_LAYER_PUSH, (variable) => {
        if (window.dataLayer === undefined) {
          console.warn('Data Layer not available');
        } else {
          window.dataLayer.push(variable);
        }
      })
      .on(this.messaging.Event.PLAYER_READY, () => this.emit(PlayerEvent.READY))
      .on(this.messaging.Event.PRODUCT_ADD_TO_CART, (data, first, product) =>
        this.emit(PlayerEvent.PRODUCT_ADD_TO_CART, data, first, product),
      )
      .on(this.messaging.Event.PRODUCT_GRANT_REACTION, (data, first, product) =>
        this.emit(PlayerEvent.PRODUCT_GRANT_REACTION, data, first, product),
      )
      .on(this.messaging.Event.PRODUCT_REVOKE_REACTION, (data, first, product) =>
        this.emit(PlayerEvent.PRODUCT_REVOKE_REACTION, data, first, product),
      )
      .on(this.messaging.Event.PRODUCT_VIEW, (data, first, product) =>
        this.emit(PlayerEvent.PRODUCT_VIEW, data, first, product),
      )
      .on(this.messaging.Event.SHOW_READY, (data) => this.emit(PlayerEvent.SHOW_READY, data))
      .on(this.messaging.Event.SHOW_START, (data) => this.emit(PlayerEvent.SHOW_START, data))
      .on(this.messaging.Event.SHOW_STOP, (data) => {
        this.emit(PlayerEvent.SHOW_STOP, data);
        if (this.element.classList.contains('lisa-hub__player--minimized')) {
          this.maximize();
        }
      });

    this.library.backdrop.on(Backdrop.Event.CLICK, () => {
      if ((this.config?.backdropBehaviour ?? 'close') === 'close') {
        this.hide();
      }
    });

    this.element = document.createElement('div');
    this.element.classList.add('lisa-hub__player');

    this.resizeHandler = this.resizePlayer.bind(this);

    window.addEventListener('resize', this.resizeHandler);
    if (this.config?.host !== undefined && this.config.host !== 'overlay') {
      document.addEventListener('scroll', this.resizeHandler);
    }

    this.resizePlayer();
  }

  private resizePlayer(): void {
    if (this.stage !== undefined) {
      const rect = this.stage.getBoundingClientRect();
      const prefix = '--lisa-hub-player-stage';

      document.documentElement.style.setProperty(
        `${prefix}-left`,
        `${rect.left + window.scrollX}px`,
      );
      document.documentElement.style.setProperty(`${prefix}-height`, `${rect.height}px`);
      document.documentElement.style.setProperty(`${prefix}-top`, `${rect.top + window.scrollY}px`);
      document.documentElement.style.setProperty(`${prefix}-width`, `${rect.width}px`);
    }

    const height = window.visualViewport?.height ?? window.innerHeight;
    document.documentElement.style.setProperty('--lisa-hub-player-height', `${height}px`);
  }

  static createFrame(url: string, className?: string | string[]): HTMLIFrameElement {
    const classNames: string[] = ['lisa-hub__player-frame'];
    if (className !== undefined) {
      classNames.push(...(Array.isArray(className) ? className : className.split(/\s+/g)));
    }

    const frame = document.createElement('iframe');
    frame.className = classNames.join(' ');
    frame.setAttribute('allow', 'web-share');
    frame.setAttribute('src', url);
    return frame;
  }

  private calculateShortSide(longSideLimit: number, orientation: Orientation): number {
    if (orientation === 'portrait') {
      return Math.round((longSideLimit / 16) * 9);
    }
    return Math.round((longSideLimit / 9) * 16);
  }

  private createCloseButton(): HTMLButtonElement {
    if (this.closeButton === undefined) {
      const classNames = ['lisa-hub__close'];
      if (this.config?.format === 'portrait') {
        classNames.push('lisa-hub__close--portrait');
      }
      if (this.config?.minimizeOnClose === true) {
        classNames.push('lisa-hub__close--minimize');
      }

      this.closeButton = document.createElement('button');
      this.closeButton.className = classNames.join(' ');
      this.closeButton.addEventListener('click', (event: MouseEvent) => {
        event.preventDefault();
        event.stopPropagation();
        if (
          this.config?.minimizeOnClose === true &&
          !this.element.classList.contains('lisa-hub__player--minimized')
        ) {
          this.minimize();
        } else {
          this.hide();
        }
      });
    }

    return this.closeButton;
  }

  private createMaximizeButton(): HTMLButtonElement {
    if (this.maximizeButton === undefined) {
      this.maximizeButton = document.createElement('button');
      this.maximizeButton.classList.add('lisa-hub__maximize');
      this.maximizeButton.appendChild(document.createElement('span'));

      this.maximizeButton.addEventListener('click', (event: MouseEvent) => {
        event.preventDefault();
        event.stopPropagation();
        this.maximize();
      });
    }

    return this.maximizeButton;
  }

  /**
   *
   * @param showId -
   * @param options -
   */
  private createShowUrl(showId?: UUID, options?: MediaPlayerOptions): string {
    const client = this.library.getClient();
    const url =
      this.library.settings?.account?.options?.playerUrl ?? `https://${client}.loveslisa.tech`;

    const path = showId === undefined ? 'default' : `s/${showId}`;

    const query = [`scope=${this.scope}`];
    if (this.config?.host === undefined || this.config.host === 'overlay') {
      query.push('ui_close_icon=right:48px');
    }
    if (this.library.config.channel !== undefined) {
      query.push(`channel=${this.library.config.channel}`);
    }
    if (this.library.config.language !== undefined) {
      query.push(`language=${this.library.config.language}`);
    }
    if (this.library.config.store !== undefined) {
      query.push(`store=${this.library.config.store}`);
    }
    if (this.library.config.querystring !== undefined) {
      query.push(`url_params=${this.library.config.querystring}`);
    }
    if (this.library.config.debug) {
      query.push(
        `debug=${this.library.config.debug === true ? 'Messaging' : this.library.config.debug}`,
      );
    }
    if (options?.seekToTimeInMs) {
      query.push('autoplay=true');
      query.push(`replay_time=${Math.floor(options.seekToTimeInMs / 1000)}`);
    }
    if (options?.initialPlayerMode === 'float') {
      query.push('player_mode=float');
    }

    return `${url}/${path}?${query.join('&')}`;
  }

  /**
   *
   */
  private unload(): void {
    if (this.currentFrame !== undefined) {
      this.currentFrame.remove();
      this.currentFrame = undefined;
    }

    this.element.className = 'lisa-hub__player lisa-hub__player--hidden';
  }

  private getOrientation(): Orientation {
    switch (this.config?.format) {
      case 'portrait':
      case 'landscape':
        return this.config.format;
    }

    const videoOutput = this.currentShow?.options?.videoOutput ?? ['portrait'];
    if (videoOutput.length === 0) {
      videoOutput.push('portrait');
    }

    if (videoOutput.indexOf('portrait') !== -1) {
      return 'portrait';
    }

    return 'landscape';
  }

  /**
   *
   */
  private hide(): void {
    if (window.LiSA.library.player === this) {
      window.LiSA.library.player = undefined;
    }

    this.emit(PlayerEvent.CLOSE);
    this.isOpen = false;

    if (this.library.quickView.open) {
      this.library.quickView.hide();
      if (!this.isOverlay) {
        this.closeButton?.classList.add('lisa-hub__close--hidden');
      }
      return;
    }

    document.getElementById('lisa-hub__meta-viewport')?.remove();

    this.library.backdrop.hide();
    this.closeButton?.classList.add('lisa-hub__close--hidden');
    this.maximizeButton?.classList.add('lisa-hub__maximize--hidden');

    if (this.element !== undefined) {
      this.element.classList.add('lisa-hub__player--closing');
      setTimeout(() => this.unload(), 200);
    }
  }

  //

  destroy(): void {
    window.removeEventListener('resize', this.resizeHandler);
    document.removeEventListener('scroll', this.resizeHandler);

    this.closeButton?.remove();
    this.maximizeButton?.remove();
    this.element.remove();
  }

  maximize(): void {
    if (this.currentFrame?.contentWindow) {
      this.messaging.maximize(this.currentFrame.contentWindow);

      this.element.classList.forEach((token) => {
        if (token.startsWith('lisa-hub__player--position-')) {
          this.element.classList.remove(token);
        }
      });
      this.element.classList.remove('lisa-hub__player--minimized');

      if (this.element.dataset.stageSelector) {
        const stage = document.querySelector(this.element.dataset.stageSelector);
        if (stage) {
          this.element.classList.replace('lisa-hub__player--overlay', 'lisa-hub__player--stage');
          this.stage?.scrollIntoView({ behavior: 'smooth' });
        }
      }

      if (!this.element.classList.contains('lisa-hub__player--stage')) {
        this.messaging.closeIcon('right:48px', this.currentFrame.contentWindow);
        this.library.backdrop.show();
        const orientation = this.getOrientation();
        this.element.classList.add(`lisa-hub__player--${orientation}`);
      }

      this.element.style.maxHeight = 'unset';
      this.element.style.maxWidth = 'unset';

      this.maximizeButton?.remove();
      this.maximizeButton = undefined;

      this.emit(PlayerEvent.MAXIMIZED);

      if (this.config?.minimizeOnClose === true) {
        this.closeButton?.classList.add('lisa-hub__close--minimize');
      }
    }
  }

  minimize(): void {
    if (this.currentFrame?.contentWindow) {
      this.messaging.minimize(this.currentFrame.contentWindow);
      this.library.backdrop.hide();

      this.element.classList.replace('lisa-hub__player--stage', 'lisa-hub__player--overlay');

      const orientation = this.getOrientation();
      const position = this.config?.minimized?.position ?? 'br';
      const longSideLimit =
        this.config?.minimized?.longSideLimit ?? (this.library.isMobile ? 240 : 320);
      const shortSideLimit = this.calculateShortSide(longSideLimit, orientation);

      this.element.classList.add('lisa-hub__player--minimized');
      if (typeof position === 'string') {
        this.element.classList.add(`lisa-hub__player--position-${position}`);
      } else {
        const keys: (keyof ElementPosition)[] = ['bottom', 'left', 'right', 'top'];
        keys.forEach((key) => {
          const positionValue = position[key];
          const value = positionValue === undefined ? 'unset' : `${positionValue}px`;
          document.documentElement.style.setProperty(`--lisa-hub-mini-player-${key}`, value);
        });

        this.element.classList.add('lisa-hub__player--position-c');
      }

      if (orientation === 'portrait') {
        this.element.style.maxHeight = `${longSideLimit}px`;
        this.element.style.maxWidth = `${shortSideLimit}px`;
      } else {
        this.element.style.maxHeight = `${shortSideLimit}px`;
        this.element.style.maxWidth = `${longSideLimit}px`;
      }

      this.element.appendChild(this.createMaximizeButton());
      this.element.appendChild(this.createCloseButton());

      this.emit(PlayerEvent.MINIMIZED);
      this.closeButton?.classList.remove('lisa-hub__close--minimize');
    }
  }

  resetElement(): void {
    this.library.backdrop.hide();

    this.element.className = 'lisa-hub__player';
    this.element.style.maxHeight = 'unset';
    this.element.style.maxWidth = 'unset';

    if (this.closeButton !== undefined) {
      const classNames = ['lisa-hub__close'];
      if (this.config?.format === 'portrait') {
        classNames.push('lisa-hub__close--portrait');
      }
      if (this.config?.minimizeOnClose === true) {
        classNames.push('lisa-hub__close--minimize');
      }
      this.closeButton.className = classNames.join(' ');
    }

    this.stage = undefined;

    this.maximizeButton?.remove();
    this.maximizeButton = undefined;
  }

  run(show?: ComposedShow, options?: MediaPlayerOptions & { spotlight?: boolean }): void {
    window.LiSA.library.player = this;

    this.resetElement();
    this.currentShow = show;

    const url = this.createShowUrl(show?.id, options);
    if (this.config?.host !== undefined && this.config.host !== 'overlay') {
      this.runInStage(this.config.host, url, { spotlight: options?.spotlight !== false });
    } else {
      this.closeButton?.classList.remove('lisa-hub__close--hidden');
      this.runInOverlay(url, options);
    }

    this.isOpen = true;
    this.emit(PlayerEvent.OPEN, show?.id);
  }

  runInOverlay(url: string, options?: MediaPlayerOptions): void {
    this.library.backdrop.show();

    const orientation = this.getOrientation();
    this.element.classList.add('lisa-hub__player--overlay', `lisa-hub__player--${orientation}`);
    document.body.appendChild(this.element);

    const meta = document.createElement('meta');
    meta.id = 'lisa-hub__meta-viewport';
    meta.setAttribute('name', 'viewport');
    meta.setAttribute('content', 'width=device-width, initial-scale=1, maximum-scale=1');
    document.head.appendChild(meta);

    this.currentFrame = Player.createFrame(url);
    this.element.appendChild(this.currentFrame);
    this.element.appendChild(this.createCloseButton());

    if (options?.initialPlayerMode === 'float') {
      this.minimize();
    }
  }

  /**
   *
   * @param selector -
   * @param url -
   */
  runInStage(selector: string, url: string, options?: { spotlight?: boolean }): void {
    const host = document.querySelector<HTMLElement>(selector);
    if (host !== null) {
      this.stage = host;

      const rect = this.stage.getBoundingClientRect();

      this.element.dataset.stageSelector = selector;
      this.element.classList.add('lisa-hub__player--stage');

      const prefix = '--lisa-hub-player-stage';
      document.documentElement.style.setProperty(
        `${prefix}-left`,
        `${rect.left + window.scrollX}px`,
      );
      document.documentElement.style.setProperty(`${prefix}-height`, `${rect.height}px`);
      document.documentElement.style.setProperty(`${prefix}-top`, `${rect.top + window.scrollY}px`);
      document.documentElement.style.setProperty(`${prefix}-width`, `${rect.width}px`);

      document.body.appendChild(this.element);

      if (this.currentFrame === undefined) {
        this.currentFrame = Player.createFrame(url);
        this.element.appendChild(this.currentFrame);
      } else {
        this.currentFrame.src = url;
      }

      if (options?.spotlight !== false) {
        this.stage.scrollIntoView({ behavior: 'smooth' });
      }
      return;
    }

    console.warn('Player host node not found. Opening player in overlay.');
    this.runInOverlay(url);
  }

  //

  grantLike(item: CarouselItemInput): void {
    if (this.currentFrame?.contentWindow) {
      this.messaging.grantLike(item, this.currentFrame.contentWindow);
    }
  }

  joinUser(input: UserJoinInput): void {
    if (this.currentFrame?.contentWindow) {
      this.messaging.joinUser(input, this.currentFrame.contentWindow);
      return;
    }

    this.emit(PlayerEvent.ERROR, 'PlayerNotAvailable', 'Unable to pass user data');
  }

  revokeLike(item: CarouselItemInput): void {
    if (this.currentFrame?.contentWindow) {
      this.messaging.revokeLike(item, this.currentFrame.contentWindow);
    }
  }
}
