import { LayoutConfig } from '../schema';

/**
 *
 */
export const DEFAULT = [576, 864, 1152, 1440] as Readonly<number[]>;

const nodes: { element: HTMLElement; config?: LayoutConfig }[] = [];

window.addEventListener('resize', () => {
  nodes.forEach((node) => {
    const all = (node.config?.breakpoints || DEFAULT).map((_) => `lisa__breakpoint--gte-${_}`);
    node.element.classList.remove(...all);
    addClassNames(node.element, node.config);
  });
});

/**
 *
 * @param element - The LiSA Library host node.
 * @param config - Optional. The LiSA Library layout config.
 */
export const addClassNames = (element: HTMLElement, config?: LayoutConfig): void => {
  const width = element.offsetWidth;
  const classNames = (config?.breakpoints || DEFAULT).reduce(
    (result, breakpoint) => {
      if (width >= breakpoint) {
        result.push(`lisa__breakpoint--gte-${breakpoint}`);
      }
      return result;
    },
    ['lisa__library-host-node'],
  );

  element.classList.add(...classNames);
};

/**
 *
 * @param element - The LiSA Library host node.
 * @param config - Optional. The LiSA Library layout config.
 */
export const register = (element: HTMLElement, config?: LayoutConfig): void => {
  addClassNames(element, config);
  nodes.push({ element, config });
};
