import { Dimension } from '../../schema';
import { EventEmitter } from 'events';
import { Slide } from './slide';
import StrictEventEmitter from 'strict-event-emitter-types';

export enum Selectors {
  TRANSPORT = 'lisa-hub__slider-transport',
  TRANSPORT_ANIMATED = 'lisa-hub__slider-transport--animated',
}

export enum TransportEvent {
  MOVE = 'slider.transport.move',
}

interface TransportEventEmitter {
  new (): StrictEventEmitter<EventEmitter, TransportEvents>;
}

interface TransportEvents {
  [TransportEvent.MOVE]: (transport: Transport) => void;
}

export class Transport extends (EventEmitter as TransportEventEmitter) {
  public static readonly Event = TransportEvent;
  public readonly direction = 'X';

  private animated = false;
  private bounds: Dimension = { width: 0, height: 0 };

  public offset = 0;
  public width = 0;

  public atStart = true;
  public atEnd = true;

  constructor(private readonly element: HTMLElement, private readonly slides: Slide[]) {
    super();

    this.element.classList.add(Selectors.TRANSPORT);

    this.width = slides.reduce((sum, slide) => sum + slide.width, 0);
    this.element.style.width = `${this.width}px`;

    this.element.addEventListener('transitionend', () => this.disableAnimation());
  }

  private calculateNewOffset(direction: 'left' | 'right'): number {
    let numSlidesTotal = this.slides.length;
    let numSlidesFullyVisible = 0;

    const currentOffset = Math.abs(this.offset);
    let newFirstVisibleIndex = direction === 'left' ? 0 : numSlidesTotal - 1;

    const boundsStart =
      direction === 'left' ? currentOffset : this.width - this.bounds.width - currentOffset;
    const boundsEnd =
      direction === 'left' ? this.bounds.width + currentOffset : this.width - currentOffset;

    while (numSlidesTotal) {
      const idx = direction === 'left' ? this.slides.length - numSlidesTotal-- : --numSlidesTotal;

      const slide = this.slides[idx] as Slide;

      const slideStart =
        direction === 'left' ? slide.offset : this.width - (slide.offset + slide.width);
      const slideEnd =
        direction === 'left' ? slide.offset + slide.width : this.width - slide.offset;

      newFirstVisibleIndex = idx;

      // before bounds start - ignore
      if (slideEnd < boundsStart) {
        continue;
      }

      // above bounds start - ignore
      if (slideStart < boundsStart) {
        continue;
      }

      // above bounds end - calculate visibility percentage
      if (slideEnd > boundsEnd) {
        const percentage = ((this.bounds.width - slideStart) / slide.width) * 100;
        if (percentage < 90 || numSlidesFullyVisible === 0) {
          // do something
        }

        if (direction === 'right') {
          newFirstVisibleIndex -= numSlidesFullyVisible > 0 ? numSlidesFullyVisible - 1 : 0;
        }
        break;
      }

      numSlidesFullyVisible++;

      if (direction === 'right' && slideEnd === boundsEnd) {
        newFirstVisibleIndex -= numSlidesFullyVisible;
        break;
      }
    }

    if (newFirstVisibleIndex < 0) {
      return 0;
    }

    return (this.slides[newFirstVisibleIndex] as Slide).offset;
  }

  private translate(duration = 250): void {
    this.element.style.transitionDuration = `${duration}ms`;
    this.element.style.transform = `translate${this.direction}(${this.offset}px)`;
  }

  public disableAnimation(): void {
    if (this.animated) {
      this.element.classList.remove(Selectors.TRANSPORT_ANIMATED);
      this.animated = false;
    }
  }

  public enableAnimation(): void {
    if (!this.animated) {
      this.element.classList.add(Selectors.TRANSPORT_ANIMATED);
      this.animated = true;
    }
  }

  public getElement(): HTMLElement {
    return this.element;
  }

  public move(offset: number, animated: boolean | number): void {
    if (animated === false || animated <= 0) {
      this.disableAnimation();
    }

    let newOffset = this.offset + offset;

    if (newOffset > 0) {
      newOffset = 0;
    }

    if (newOffset < -1 * (this.width - this.bounds.width)) {
      newOffset = -1 * (this.width - this.bounds.width);
    }

    if (newOffset === this.offset) {
      return;
    }

    this.offset = newOffset;
    this.atStart = this.offset === 0;
    this.atEnd = this.width + this.offset === this.bounds.width;

    this.translate(typeof animated === 'boolean' ? Math.abs(offset) : animated);
  }

  public next(): void {
    let newOffset = this.calculateNewOffset('left');
    if (newOffset > this.width - this.bounds.width) {
      newOffset = this.width - this.bounds.width;
    }

    if (newOffset === Math.abs(this.offset)) {
      return;
    }

    this.offset = -1 * newOffset;
    this.translate(this.bounds.width / 3);
  }

  public previous(): void {
    let newOffset = this.calculateNewOffset('right');
    if (newOffset < 0) {
      newOffset = 0;
    }

    if (newOffset === Math.abs(this.offset)) {
      return;
    }

    this.offset = -1 * newOffset;
    this.translate(this.bounds.width / 3);
  }

  public setBounds(bounds: Dimension): void {
    this.bounds.width = bounds.width;
    this.bounds.height = bounds.height;
  }
}
