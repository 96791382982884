import { SectionPage, SectionPaginationConfig } from '../../schema';

export class Pagination {
  private numPages: number;

  public previous: number;
  public next: number;
  public pages: SectionPage[];

  constructor(
    private numItems: number,
    private limit: number,
    private current: number,
    public config: SectionPaginationConfig,
  ) {
    this.numPages = Math.ceil(this.numItems / this.limit);

    this.previous = this.current - 1;
    this.next = this.current + 1;
    this.pages = this.createPages();
  }

  private createPages(): SectionPage[] {
    const pages = [...Array(this.numPages).keys()].map((_) => _ + 1);

    if (this.numPages <= 7) {
      return this.toSectionPages(pages);
    }

    if (this.current <= 4) {
      return [
        ...this.toSectionPages(pages.splice(0, 5)),
        { label: '...', page: Math.min(Math.max(this.current, 5) + 10, this.numPages - 1) },
        ...this.toSectionPages([pages.pop()]),
      ];
    }

    if (this.numPages - 4 <= this.current) {
      return [
        ...this.toSectionPages([pages.shift()]),
        { label: '...', page: Math.max(this.current - 10, 2) },
        ...this.toSectionPages(pages.splice(-5, 5)),
      ];
    }

    return [
      ...this.toSectionPages([pages.shift()]),
      { label: '...', page: Math.max(this.current - 10, 2) },
      ...this.toSectionPages(pages.splice(this.current - 3, 3)),
      { label: '...', page: Math.min(Math.max(this.current, 5) + 10, this.numPages - 1) },
      ...this.toSectionPages([pages.pop()]),
    ];
  }

  private toSectionPages(items: (number | undefined)[]): SectionPage[] {
    return items
      .filter((page): page is number => page !== undefined)
      .map((page) => ({ label: `${page}`, page }));
  }

  getCurrent(): number {
    return this.current;
  }

  hasNext(): boolean {
    return this.next <= this.numPages;
  }

  hasPrevious(): boolean {
    return this.previous > 0;
  }

  update(current: number): void {
    this.current = current;
    this.previous = this.current - 1;
    this.next = this.current + 1;
    this.pages = this.createPages();
  }
}
