import * as Sections from './sections';
import * as Show from './show';

import { ComposedShow, ScrollableResourceList } from '@hello-lisa/schemas';

import { Library } from './library';
import { Section } from './section';
import { SectionConfig } from '../schema';
import { getShowState } from '@hello-lisa/utils';

/**
 *
 * @param showList - The scrollable resource list of all fetched composed shows
 * @param library - The LiSA library instance.
 */
export const build = (
  showList: ScrollableResourceList<ComposedShow>,
  library: Library,
): Section[] => {
  const allShows = showList.items.sort(Section.sort.bind(null, 1));

  allShows.forEach((source) => {
    const show = Show.fromComposedShow(source, library.config);
    library.addShow(show, source);
    return show;
  });

  const sectionConfigs: SectionConfig[] =
    library.config.sections === undefined ? Sections.DEFAULT : library.config.sections;

  return sectionConfigs
    .map((sectionConfig) => {
      const shows = showList.items.filter((show) => getShowState(show) === sectionConfig.state);
      return new Section(sectionConfig, shows, library);
    })
    .filter((section) => section.getItems().length > 0);
};
