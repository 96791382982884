import * as Utils from '../utilities';

import { Library, LibraryEvent } from '../library';
import { SectionConfig, Show } from '../../schema';
import { ShortClips, Slider } from '..';

import { ComposedShow } from '@hello-lisa/schemas';
import { EventEmitter } from 'events';
import Handlebars from 'handlebars';
import { Pagination } from './pagination';
import StrictEventEmitter from 'strict-event-emitter-types';
import { dateFromDateTime } from '@hello-lisa/utils';
import { fromComposedShow } from '../show';
import { launchShow } from '../autoplay';
import { section } from '../../template';
import { touchEnabled } from '../device';

export enum SectionEvent {
  IMPRESSION = 'library-item.impression',
}

interface SectionEventEmitter {
  new (): StrictEventEmitter<EventEmitter, SectionEvents>;
}

interface SectionEvents {
  [SectionEvent.IMPRESSION]: () => void;
}

enum Selectors {
  PAGINATION_ACTIVE = 'lisa__section-pagination--active',
  PAGINATION_DISABLED = 'lisa__section-pagination--disabled',
  PAGINATION_PAGE = 'lisa__section-pagination--page',
}

export class Section extends (EventEmitter as SectionEventEmitter) {
  private readonly element: HTMLDivElement;
  private readonly layoutView: 'grid' | 'list' | 'slider';

  public readonly Event = SectionEvent;

  private items: Show[] = [];
  private pagination?: Pagination;
  private slider?: Slider.Slider;

  private delegate: HandlebarsTemplateDelegate;

  static sort(order: -1 | 1, a: ComposedShow, b: ComposedShow): number {
    const aDate = dateFromDateTime(a.date);
    const bDate = dateFromDateTime(b.date);
    return aDate.getTime() > bDate.getTime() ? order : -1 * order;
  }

  constructor(
    private readonly config: SectionConfig,
    private readonly shows: ComposedShow[],
    public readonly library: Library,
  ) {
    super();

    const template = this.config.template || section;
    this.delegate = Handlebars.compile(template);

    this.element = document.createElement('div');

    this.shows.sort(Section.sort.bind(null, this.config.order));
    this.items = this.shows.map((source) =>
      fromComposedShow(source, this.library.config, this.config),
    );

    this.layoutView = this.config.layout?.view ?? this.library.config.layout?.view ?? 'slider';
    if (
      this.layoutView !== 'slider' &&
      this.items.length > this.config.limit &&
      this.config.limit > 0
    ) {
      this.pagination = new Pagination(this.items.length, this.config.limit, 1, {
        i18n: {
          next: library.config.i18n?.library?.paginationNext ?? 'Next',
          previous: library.config.i18n?.library?.paginationPrevious ?? 'Previous',
        },
      });
    }

    this.render(this.library.hostNode);
  }

  getElement(): HTMLDivElement {
    return this.element;
  }

  getItems(): Show[] {
    const start = this.pagination ? this.config.limit * (this.pagination?.getCurrent() - 1) : 0;
    const end = start + this.config.limit;
    return this.items.slice(start, end);
  }

  getLayoutView(): string {
    return this.layoutView;
  }

  getPagination(): Pagination | undefined {
    return this.pagination;
  }

  private registerEventHandler(): void {
    this.element.querySelectorAll<HTMLElement>('.lisa__show-cta-play').forEach((cta) => {
      if (touchEnabled()) {
        cta.addEventListener('touchstart', (event) => event.stopPropagation());
        cta.addEventListener('touchend', (event) => event.stopPropagation());
      }

      cta.addEventListener('click', (event) => {
        launchShow(this.library, event).catch((_) => {
          // silent fail
        });
      });
    });

    if (this.library.config.shortClips) {
      ShortClips.initialize(this.element, this.library);
    }

    if (this.pagination === undefined) {
      return;
    }

    const previous = this.element.querySelector('.lisa__section-pagination--previous');
    const next = this.element.querySelector('.lisa__section-pagination--next');

    if (previous === null || next === null) {
      return;
    }

    const selector = [
      `.${Selectors.PAGINATION_PAGE}`,
      `button[data-page="${this.pagination.getCurrent()}"]`,
    ].join(' ');
    const current = this.element.querySelector(selector);
    current?.parentElement?.classList.add(Selectors.PAGINATION_ACTIVE);

    let method: 'add' | 'remove';

    method = this.pagination?.hasPrevious() === true ? 'remove' : 'add';
    previous.classList[method](Selectors.PAGINATION_DISABLED);

    method = this.pagination?.hasNext() === true ? 'remove' : 'add';
    next.classList[method](Selectors.PAGINATION_DISABLED);

    this.element.querySelectorAll('button').forEach((button) => {
      button.addEventListener('click', (event) => {
        event.stopPropagation();
        if (
          this.pagination === undefined ||
          button.parentElement?.classList.contains(Selectors.PAGINATION_DISABLED)
        ) {
          event.preventDefault();
          return;
        }

        this.pagination.update(parseInt(button.dataset.page ?? '1'));
        this.render();
        this.element.scrollIntoView();
      });
    });
  }

  render(parent?: HTMLElement | null): void {
    if (this.items.length === 0) {
      return;
    }

    this.slider?.removeAllListeners();

    const context = {
      items: this.getItems(),
      pagination: this.getPagination(),
      title: this.config.title,
      variations: [Utils.toKebabCase(this.config.state), this.layoutView],
    };

    this.element.innerHTML = this.delegate(context);

    if (parent) {
      parent.appendChild(this.element);
    }

    this.registerEventHandler();

    if (this.layoutView === 'slider') {
      this.slider = Slider.initialize(this);
      if (this.slider !== undefined) {
        this.slider
          .on(Slider.SliderEvent.SLIDE_END, () => this.library.emit(LibraryEvent.SLIDER_END))
          .on(Slider.SliderEvent.SLIDE_START, () => this.library.emit(LibraryEvent.SLIDER_START));
      }
    }
  }
}
