import { API, Breakpoints, Utils } from '.';
import { Config, Hub } from '../schema';

import { Library } from './library';
import { closestAncestor } from './utilities';
import { uuid } from '@hello-lisa/uuid';

export const initialize = async (hub: Hub): Promise<Library[]> => {
  const foo = window.setInterval(() => {
    if (foo !== undefined) {
      /* silent pass */
    }
  }, 1000);

  const promises = hub.configs.map((config) => {
    config.id = config.id ?? uuid.create();

    injectConfigOptionsFromRequest(config);
    const instance = createLibraryInstance(config);
    if (instance.hostNode !== null) {
      Breakpoints.register(instance.hostNode, config.layout);
    }
    return instance.initialize();
  });

  return await Promise.all(promises).then((result) => {
    if (result.length === 0) {
      return result;
    }

    const library = result[0];
    if (library === undefined) {
      return result;
    }

    document.addEventListener('click', (event) => {
      const trigger = closestAncestor(event.target as HTMLElement, (el) => {
        return el?.dataset.showId !== undefined;
      });

      const showId = trigger?.dataset.showId;
      if (showId === undefined) {
        return;
      }

      void API.Shows.getShow(library.getClient(), showId).then((show) => {
        if (show !== undefined) {
          library.player.run(show);
        }
      });
    });

    return result;
  });
};

const injectConfigOptionsFromRequest = (config: Config): void => {
  const match = /lisa_debug=([^&]+)/.exec(window.location.search);
  if (match !== null) {
    config.debug = match[1] === '1' || match[1] === 'true' ? true : match[1];
  }
};

export const createLibraryInstance = (config: Config): Library => {
  let hostNode: HTMLElement | null = null;

  if (
    config.sections === undefined ||
    (Array.isArray(config.sections) && config.sections.length > 0)
  ) {
    // sections are defined, so we need a host node to inject library markup
    if (!Utils.isStringValue(config.hostNode)) {
      throw new Error(
        'With sections being defined, a LiSA Library host node query selector is mandatory. Please provide a query selector using config option `hostNode`',
      );
    }

    const rootNode = Utils.findElement(config.hostNode);
    if (rootNode === null) {
      throw new Error(`Unable to find host node using selector: ${config.hostNode}`);
    }

    hostNode = document.createElement('div');
    hostNode.dataset.instanceId = config.id;
    rootNode.appendChild(hostNode);
  }

  return new Library(config, hostNode);
};
