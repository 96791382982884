let isIOS15: boolean | undefined;
let isTouchEnabled: boolean | undefined;

export const iOS15 = (): boolean => {
  if (isIOS15 === undefined) {
    isIOS15 = /iPhone\sOS\s15/.test(window.navigator.userAgent);
  }
  return isIOS15;
};

export const touchEnabled = (): boolean => {
  if (isTouchEnabled === undefined) {
    isTouchEnabled = window.matchMedia('(pointer: coarse)').matches;
  }
  return isTouchEnabled;
};
