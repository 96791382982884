import { Camera } from './camera';
import { EventEmitter } from 'events';
import StrictEventEmitter from 'strict-event-emitter-types';
import { touchEnabled } from '../device';

export enum Selectors {
  ARROWS = 'lisa-hub__slider-arrows',
  ARROWS_LEFT = 'lisa-hub__slider-arrows--left',
  ARROWS_HIDDEN = 'lisa-hub__slider-arrows--hidden',
  ARROWS_RIGHT = 'lisa-hub__slider-arrows--right',
}

export enum ArrowsEvent {
  MOVE = 'slider.arrows.move',
}

interface ArrowsEventEmitter {
  new (): StrictEventEmitter<EventEmitter, ArrowsEvents>;
}

interface ArrowsEvents {
  [ArrowsEvent.MOVE]: (direction: 'left' | 'right') => void;
}

export class Arrows extends (EventEmitter as ArrowsEventEmitter) {
  public static readonly Event = ArrowsEvent;

  private readonly left: HTMLButtonElement;
  private readonly right: HTMLButtonElement;

  constructor(private readonly camera: Camera) {
    super();

    this.left = document.createElement('button');
    this.left.classList.add(Selectors.ARROWS, Selectors.ARROWS_LEFT);

    this.right = document.createElement('button');
    this.right.classList.add(Selectors.ARROWS, Selectors.ARROWS_RIGHT);

    this.camera.getElement().parentElement?.appendChild(this.left);
    this.camera.getElement().parentElement?.appendChild(this.right);

    this.camera.on(Camera.Event.RESIZE, () => this.updateVisibility());
    this.updateVisibility();

    this.registerEventListener();
  }

  private registerEventListener(): void {
    this.left.addEventListener('click', (event) => {
      event.stopPropagation();
      this.emit(ArrowsEvent.MOVE, 'left');
    });
    this.right.addEventListener('click', (event) => {
      event.stopPropagation();
      this.emit(ArrowsEvent.MOVE, 'right');
    });

    if (touchEnabled()) {
      this.left.addEventListener('touchstart', (event) => event.stopPropagation());
      this.left.addEventListener('touchend', (event) => event.stopPropagation());
      this.right.addEventListener('touchstart', (event) => event.stopPropagation());
      this.right.addEventListener('touchend', (event) => event.stopPropagation());
    }
  }

  private updateVisibility(): void {
    const method = this.camera.transport.width > this.camera.width ? 'remove' : 'add';
    this.left.classList[method](Selectors.ARROWS_HIDDEN);
    this.right.classList[method](Selectors.ARROWS_HIDDEN);
  }
}
