import './assets/styles/style.scss';

import Handlebars, { HelperOptions } from 'handlebars';
import { Snippets, initialize } from './lib';

import { Hub } from './schema';

(function (): void {
  Handlebars.registerHelper(
    'eq',
    function (
      a: number | string,
      b: number | string | HelperOptions,
      options?: HelperOptions,
    ): string {
      if (arguments.length === 2) {
        options = b as HelperOptions;
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        b = options?.hash.compare as number | string;
      } else if (options === undefined) {
        throw new Error('Too few arguments');
      }
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return a === b ? options.fn(this) : options.inverse(this);
    },
  );

  Handlebars.registerHelper(
    'neq',
    function (
      a: number | string,
      b: number | string | HelperOptions,
      options?: HelperOptions,
    ): string {
      if (arguments.length === 2) {
        options = b as HelperOptions;
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        b = options?.hash.compare as number | string;
      } else if (options === undefined) {
        throw new Error('Too few arguments');
      }
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return a !== b ? options.fn(this) : options.inverse(this);
    },
  );
})();

const library: Hub = window.LiSA?.library;

const initLibrary = (library: Hub): void => {
  initialize(library)
    .then((instances) => {
      library.players = {};
      instances.forEach((instance) => {
        library.players[instance.id] = instance.player;
      });

      library.destroy = (): void => {
        let length = instances.length;
        while (length--) {
          instances[length]?.destroy();
        }
      };

      library.reInit = (): void => initLibrary(library);

      instances.forEach((instance) => {
        if (instance.config.snippets === true) {
          Snippets.initialize(instance);
        }
      });

      if ('IntersectionObserver' in window) {
        const lazyImages = [].slice.call(
          document.querySelectorAll('img.lisa__show-media-asset--lazy'),
        );
        const lazyImageObserver = new IntersectionObserver((entries, observer) => {
          entries.forEach((entry) => {
            // Do these if the target intersects with the root
            if (entry.isIntersecting) {
              const lazyImage = entry.target as HTMLImageElement;
              lazyImage.src = lazyImage.dataset.src || '';
              lazyImage.classList.remove('lisa__show-media-asset--lazy');
              observer.unobserve(lazyImage);
            }
          });
        });
        lazyImages.forEach((lazyImage) => lazyImageObserver.observe(lazyImage));
      }
    })
    .catch((err) => console.error('Failed to initialize LiSA Library', err));
};

initLibrary(library);
