import { Context } from '@hello-lisa/lib-messaging';
import { Library } from '../library';
import { SnippetsPlayer } from './player';
import { UUID } from '@hello-lisa/uuid';

export type SnippetsOptions = {
  initialPlayerMode?: 'cover' | 'float';
  seekToTimeInMs?: number;
};

export type SnippetsPlaylistItem = {
  mediaItemId: UUID;
  mediaItemType: 'show';
};

export const initialize = (library: Library): void => {
  const context = new Context(library.config.client);
  const player = new SnippetsPlayer(context, 'snippets', library);

  window._lscSnippetsPlayer = player;
  window.dispatchEvent(new CustomEvent('lsc:widget:snippets:ready', { detail: { player } }));
};
