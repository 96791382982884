import { Arrows } from './arrows';
import { Camera } from './camera';
import { EventEmitter } from 'events';
import { Slide } from './slide';
import StrictEventEmitter from 'strict-event-emitter-types';
import { Transport } from './transport';

export enum Selectors {
  SLIDER = 'lisa-hub__slider',
  SLIDER_ITEM = 'lisa-hub__slider-item',
}

export enum SliderEvent {
  NEXT = 'slider.next',
  PREVIOUS = 'slider.previous',
  SLIDE_END = 'slider.slide-end',
  SLIDE_START = 'slider.slide-start',
}

interface SliderEventEmitter {
  new (): StrictEventEmitter<EventEmitter, SliderEvents>;
}

interface SliderEvents {
  [SliderEvent.NEXT]: () => void;
  [SliderEvent.PREVIOUS]: () => void;
  [SliderEvent.SLIDE_END]: () => void;
  [SliderEvent.SLIDE_START]: () => void;
}

export interface SliderOptions {
  selector: string;
}

export class Slider extends (EventEmitter as SliderEventEmitter) {
  private readonly touchEnabled = window.matchMedia('only screen and (pointer:coarse)').matches;

  private sliding = false;
  private slidingTimeout?: number;

  public static readonly Event = SliderEvent;

  public arrows?: Arrows;
  public camera?: Camera;
  public transport?: Transport;
  public slides: Slide[] = [];

  public readonly Event = SliderEvent;

  constructor(private readonly element: HTMLElement, private readonly options: SliderOptions) {
    super();

    this.initialize();
  }

  private initialize(): void {
    if (!this.element) {
      return;
    }

    if (!this.touchEnabled) {
      this.element.addEventListener('wheel', (event) => {
        if (
          (this.transport?.atEnd === false && this.transport?.atStart === false) ||
          Math.abs(event.deltaX) > Math.abs(event.deltaY)
        ) {
          event.preventDefault();
        }
      });
    }

    const transport = document.createElement('div');
    this.element.appendChild(transport);

    this.element.querySelectorAll<HTMLElement>(this.options.selector).forEach((_) => {
      this.slides.push(new Slide(_));
      _.classList.add(Selectors.SLIDER_ITEM);
      transport.appendChild(_);
    });

    this.slides.reduce((offset, slide) => {
      slide.offset = offset;
      return offset + slide.width;
    }, 0);

    this.transport = new Transport(transport, this.slides);
    this.camera = new Camera(this.transport);

    if (!this.touchEnabled) {
      this.arrows = new Arrows(this.camera);
    }

    this.camera.on(Camera.Event.MOVE, (offset, animated) => {
      if (animated) {
        this.transport?.enableAnimation();
      }
      this.transport?.move(offset, animated);

      this.startSlide();
    });

    if (this.arrows) {
      this.arrows.on(Arrows.Event.MOVE, (direction) => {
        this.transport?.enableAnimation();
        if (direction === 'left') {
          this.transport?.previous();
        } else {
          this.transport?.next();
        }
      });
    }
  }

  startSlide(): void {
    if (!this.sliding) {
      this.sliding = true;
      this.emit(SliderEvent.SLIDE_START);
      return;
    }

    window.clearTimeout(this.slidingTimeout);
    this.slidingTimeout = undefined;

    this.slidingTimeout = window.setTimeout(() => {
      this.sliding = false;
      this.emit(SliderEvent.SLIDE_END);
    }, 100);
  }
}
