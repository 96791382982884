import { MediaPlayer, MediaPlayerOptions, PlaylistItem } from '@hello-lisa/lib-media-player';

import { API } from '..';
import { ComposedShow } from '@hello-lisa/schemas';
import { Context } from '@hello-lisa/lib-messaging';
import { Library } from '../library';

export class SnippetsPlayer extends MediaPlayer {
  constructor(context: Context, id: string, private readonly library: Library) {
    super(context, id);
  }

  // Overrides

  open(item?: PlaylistItem, options?: MediaPlayerOptions): void {
    this.library.player.run({ id: item?.mediaItemId } as ComposedShow, options);
  }

  // Domain

  async initializeWithProduct(reference: string, options?: MediaPlayerOptions): Promise<void> {
    const hasSnippets = await API.Snippets.hasSnippets(this.library.config.client, reference);
    if (!hasSnippets) {
      return;
    }

    const snippets = await API.Snippets.getSnippets(this.library.config.client, reference);
    const item = snippets.shift();
    if (item === undefined) {
      return;
    }

    this.open(
      { mediaItemId: item.showId, mediaItemType: 'show' as 'story' },
      {
        ...options,
        seekToTimeInMs: item.startTimeInMs,
        initialPlayerMode: options?.initialPlayerMode ?? 'float',
      },
    );
  }
}
