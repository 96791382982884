import { EventEmitter } from 'events';
import { Library } from './library';
import StrictEventEmitter from 'strict-event-emitter-types';

const scrollKeys = [
  'ArrowDown',
  'ArrowLeft',
  'ArrowRight',
  'ArrowUp',
  'End',
  'Home',
  'PageDown',
  'PageUp',
  'Space',
];

enum Class {
  BACKDROP = 'lisa-hub__backdrop',
  BACKDROP_ACTIVE = 'lisa-hub__backdrop--active',
  BACKDROP_HIDDEN = 'lisa-hub__backdrop--hidden',
  BACKDROP_INACTIVE = 'lisa-hub__backdrop--inactive',
  SCROLL_DISABLED = 'lisa-hub__scroll--disabled',
}

export enum BackdropEvent {
  CLICK = 'backdrop.click',
  CLOSE = 'backdrop.close',
  CLOSED = 'backdrop.closed',
  OPEN = 'backdrop.open',
  OPENED = 'backdrop.opened',
}

interface BackdropEvents {
  [BackdropEvent.CLICK]: void;
  [BackdropEvent.CLOSE]: void;
  [BackdropEvent.CLOSED]: void;
  [BackdropEvent.OPEN]: void;
  [BackdropEvent.OPENED]: void;
}

type BackdropEventEmitter = StrictEventEmitter<EventEmitter, BackdropEvents>;

export class Backdrop extends (EventEmitter as { new (): BackdropEventEmitter }) {
  public readonly Event = BackdropEvent;
  public static readonly Event = BackdropEvent;

  private node?: HTMLDivElement;
  private open = false;
  private timeout?: number;

  constructor(public readonly library: Library) {
    super();

    this.open = false;
  }

  private getBackdropNode(): HTMLDivElement {
    if (this.node === undefined) {
      const node = document.createElement('div');
      node.classList.add(Class.BACKDROP, Class.BACKDROP_HIDDEN, Class.BACKDROP_INACTIVE);
      this.node = node;
      this.node.addEventListener('click', (event: MouseEvent) => {
        event.preventDefault();
        this.emit(BackdropEvent.CLICK);
      });
      document.body.append(this.node);
    }
    return this.node;
  }

  destroy(): void {
    this.node?.remove();
  }

  disableScroll(): void {
    const scrollY = window.scrollY;
    document.documentElement.style.setProperty('--lisa-hub-body-scroll-y', `-${scrollY}px`);
    document.body.classList.add(Class.SCROLL_DISABLED);
  }

  enableScroll(): void {
    const scroll = document.documentElement.style.getPropertyValue('--lisa-hub-body-scroll-y');
    const top = parseInt(scroll) * -1;
    document.body.classList.remove(Class.SCROLL_DISABLED);
    document.documentElement.style.removeProperty('--lisa-hub-body-scroll-y');
    window.scrollTo(0, top);
  }

  hide(): void {
    if (!this.open) {
      return;
    }

    this.emit(BackdropEvent.CLOSE);

    this.open = false;
    this.getBackdropNode().classList.replace(Class.BACKDROP_ACTIVE, Class.BACKDROP_HIDDEN);
    this.timeout = window.setTimeout(() => {
      this.getBackdropNode().classList.add(Class.BACKDROP_INACTIVE);
      this.enableScroll();
      this.emit(BackdropEvent.CLOSED);
      this.timeout = undefined;
    }, Library.ANIMATION_DURATION);
  }

  preventScroll(event: Event | KeyboardEvent): void {
    if (event instanceof KeyboardEvent) {
      if (scrollKeys.indexOf(event.code) !== -1) {
        event.preventDefault();
      }
    } else {
      event.preventDefault();
    }
  }

  show(): void {
    if (this.open) {
      return;
    }
    this.emit(BackdropEvent.OPEN);

    this.open = true;
    if (this.timeout !== undefined) {
      window.clearTimeout(this.timeout);
      this.timeout = undefined;
    } else {
      this.disableScroll();
    }

    this.getBackdropNode().classList.replace(Class.BACKDROP_HIDDEN, Class.BACKDROP_ACTIVE);
    this.getBackdropNode().classList.remove(Class.BACKDROP_INACTIVE);

    this.emit(BackdropEvent.OPENED);
  }
}
