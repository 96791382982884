import { ClientSettings, ComposedShow, ScrollableResourceList } from '@hello-lisa/schemas';
import { Config, Show } from '../schema';
import { Settings, Shows } from './api';
import { UUID, uuid } from '@hello-lisa/uuid';
import { Utils, build, launchDefaultView, launchShow } from '.';

import { Backdrop } from './backdrop';
import { EventEmitter } from 'events';
import { Player } from './player';
import { QuickView } from './quick-view';
import StrictEventEmitter from 'strict-event-emitter-types';
import { autoplay } from './autoplay';

export enum LibraryEvent {
  PLAYER_CLOSE = 'player.close',
  PLAYER_OPEN = 'player.open',
  SLIDER_END = 'slider.end',
  SLIDER_START = 'slider.start',
}

interface LibraryEvents {
  [LibraryEvent.PLAYER_CLOSE]: void;
  [LibraryEvent.PLAYER_OPEN]: (showId: UUID, url: string) => void;
  [LibraryEvent.SLIDER_START]: void;
  [LibraryEvent.SLIDER_END]: void;
}

type LibraryEventEmitter = StrictEventEmitter<EventEmitter, LibraryEvents>;

export class Library extends (EventEmitter as { new (): LibraryEventEmitter }) {
  private readonly shows = new Map<UUID, { show: Show; source?: ComposedShow }>();

  public static ANIMATION_DURATION = 200;

  public readonly Event = LibraryEvent;
  public readonly Utils = Utils;
  public readonly backdrop: Backdrop;
  public readonly id: string;
  public readonly isMobile: boolean;
  public readonly player: Player;
  public readonly quickView: QuickView;

  public settings?: ClientSettings;

  /**
   *
   * @param config - The LiSA Library configuration object.
   * @param hostNode - The HTML element that act's as a host for all LiSA
   * library markup.
   */
  constructor(public readonly config: Config, public readonly hostNode: HTMLElement | null) {
    super();

    this.id = config.id ?? uuid.create();

    this.backdrop = new Backdrop(this);
    this.player = new Player(this);
    this.quickView = new QuickView(this);

    this.isMobile = window.matchMedia('only screen and (max-width: 640px)').matches;

    this.quickView.on(this.quickView.Event.OPEN, () => this.backdrop.show());
    if (this.config.player?.host !== undefined) {
      this.quickView.on(this.quickView.Event.CLOSED, () => this.backdrop.hide());
    }

    this.backdrop.on(this.backdrop.Event.CLICK, () => this.quickView.hide());
  }

  addShow(show: Show, source?: ComposedShow): void {
    this.shows.set(show.id, { show, source });
  }

  destroy(): void {
    if (!this.hostNode) {
      return;
    }

    this.backdrop.destroy();
    this.player.destroy();
    this.quickView.destroy();

    this.hostNode.innerHTML = '';
    this.hostNode.classList.remove(
      ...Array.from(this.hostNode.classList).filter((_) => _.startsWith('lisa__')),
    );
  }

  getClient(): string {
    return this.config.client === 'lisa' ? 'demo' : this.config.client;
  }

  getShow(showId: UUID): Show | undefined {
    return this.shows.get(showId)?.show;
  }

  getSources(): ComposedShow[] {
    const shows: ComposedShow[] = [];
    [...this.shows.values()].forEach((_) => {
      if (_.source !== undefined) {
        shows.push(_.source);
      }
    });
    return shows;
  }

  getSource(showId: UUID): ComposedShow | undefined {
    return this.shows.get(showId)?.source;
  }

  hasShows(): boolean {
    return this.shows.size > 0;
  }

  async initialize(): Promise<Library> {
    const tasks = [
      Settings.getSettings(this.config.client),
      Shows.getShows(this.config.client, this.config.channel),
    ];

    return Promise.all(tasks)
      .then((result) => {
        this.settings = result[0] as ClientSettings;
        const showList = result[1] as ScrollableResourceList<ComposedShow>;

        showList.items.forEach((item) => (item.settings = this.settings));

        build(showList, this);

        return this;
      })
      .then((instance) => {
        if (this.config.on?.ready !== undefined) {
          this.config.on.ready(instance);
        }

        const id = autoplay(instance);
        if (id !== undefined) {
          launchShow(instance, id, { spotlight: false }).catch((_) => {
            // silent fail
          });
        }

        if (instance.config.player?.host !== undefined && id === undefined) {
          const playerNode = Utils.findElement(instance.config.player.host);
          if (playerNode === null) {
            console.error(
              `Unable to find player host node using selector: ${instance.config.player.host}`,
            );
          }
          launchDefaultView(instance);
        }

        return this;
      })
      .catch((err: Error) => {
        if (this.config.on?.error !== undefined) {
          this.config.on.error(err);
        } else {
          console.error(err);
        }

        return this;
      });
  }
}
