import { Library } from '../library';
import { ShortClipPlayer } from './short-clip-player';

enum Selectors {
  CLIP_PLAYBACK = 'lisa__show-media--clip-playback',
}

let globalEventListenersCreated = false;

export const initialize = (element: HTMLElement, library: Library): void => {
  let currentLibraryPlayer: ShortClipPlayer;

  let sliding = false;
  if (!globalEventListenersCreated) {
    globalEventListenersCreated = true;
    library
      .on(library.Event.SLIDER_END, () => (sliding = false))
      .on(library.Event.SLIDER_START, () => {
        sliding = true;
        currentLibraryPlayer?.pause();
      });
  }

  element.querySelectorAll<HTMLElement>('.lisa__show').forEach((showElement) => {
    const players: ShortClipPlayer[] = [];
    let currentPlayer: ShortClipPlayer;

    showElement.querySelectorAll('video').forEach((videoElement, idx, all) => {
      const player = new ShortClipPlayer(videoElement, idx, all.length === 1);
      player
        .on(player.Event.PAUSE, () => showElement.classList.remove(Selectors.CLIP_PLAYBACK))
        .on(player.Event.RESUME, () => showElement.classList.add(Selectors.CLIP_PLAYBACK))
        .on(player.Event.FINAL, (index) => (currentPlayer = playNextShortClip(players, index)))
        .on(player.Event.START, () => showElement.classList.add(Selectors.CLIP_PLAYBACK));

      players.push(player);
    });

    if (players.length === 0) {
      return;
    }

    showElement.addEventListener('mouseenter', (_) => {
      if (sliding) {
        return;
      }

      players.forEach((player) => player.init());

      if (currentPlayer === undefined) {
        currentPlayer = playNextShortClip(players, 0);
        currentLibraryPlayer = currentPlayer;
      } else {
        currentPlayer.resume();
      }
    });

    showElement.addEventListener('mouseleave', (_) => {
      const currentPlayer = players.find((player) => player.isPlaying());
      if (currentPlayer === undefined) {
        return;
      }
      currentPlayer.pause();
    });
  });
};

/**
 *
 */
const playNextShortClip = (players: ShortClipPlayer[], currentIndex: number): ShortClipPlayer => {
  players[currentIndex]?.fadeOut();
  if (currentIndex === players.length - 1) {
    currentIndex = 0;
  } else {
    currentIndex++;
  }
  const currentPlayer = players[currentIndex];
  if (currentPlayer === undefined) {
    // this should never occur
    throw new Error('Player not found.');
  }
  currentPlayer.fadeIn();
  return currentPlayer;
};
