import { Dimension, Position } from '../../schema';

import { EventEmitter } from 'events';
import StrictEventEmitter from 'strict-event-emitter-types';
import { Transport } from './transport';
import { touchEnabled } from '../device';

export enum Selectors {
  CAMERA = 'lisa-hub__slider-camera',
  CAMERA_SCROLLBAR = 'lisa-hub__slider-scroll',
  CAMERA_TOUCH = 'lisa-hub__slider-camera--touch',
}

export enum CameraEvent {
  MOVE = 'slider.camera.move',
  RESIZE = 'slider.camera.resize',
}

interface CameraEventEmitter {
  new (): StrictEventEmitter<EventEmitter, CameraEvents>;
}

interface CameraEvents {
  [CameraEvent.MOVE]: (offset: number, animated: boolean | number, camera: Camera) => void;
  [CameraEvent.RESIZE]: (camera: Camera) => void;
}

interface Move {
  position: Position;
  time: number;
}

export class Camera extends (EventEmitter as CameraEventEmitter) implements Dimension {
  private readonly touchEnabled = window.matchMedia('only screen and (pointer:coarse)').matches;

  public static readonly Event = CameraEvent;

  private readonly element: HTMLDivElement;
  private readonly scrollbar?: HTMLDivElement;
  private readonly scrollbarHandle?: HTMLSpanElement;

  private moveInProgress = false;
  private moves: Move[] = [];

  public height = 0;
  public width = 0;

  constructor(public readonly transport: Transport) {
    super();

    this.element = document.createElement('div');
    this.element.classList.add(Selectors.CAMERA);

    if (this.touchEnabled) {
      this.element.classList.add(Selectors.CAMERA_TOUCH);
    }

    this.transport.getElement().parentElement?.append(this.element);
    this.element.appendChild(this.transport.getElement());

    if (this.touchEnabled) {
      this.scrollbar = document.createElement('div');
      this.scrollbarHandle = document.createElement('span');
      this.scrollbar.classList.add(Selectors.CAMERA_SCROLLBAR);
      this.scrollbar.appendChild(this.scrollbarHandle);
      this.element.parentElement?.appendChild(this.scrollbar);
    }

    this.registerEventListener();
    this.updateDimension();
  }

  private easeOut(): void {
    const numMoves = this.moves.length;
    if (numMoves <= 3) {
      return;
    }

    const last = this.moves[numMoves - 1] as Move;
    const secondToLast = this.moves[numMoves - 2] as Move;

    if (last.position.x === secondToLast.position.x) {
      return;
    }

    let direction: 'left' | 'right' | undefined = undefined;

    const initial: Move = { position: { x: 0, y: 0 }, time: -1 };

    const movesConsidered = Math.min(numMoves, 10);

    const first = [...Array(movesConsidered).keys()].reduce((previous, current) => {
      const index = numMoves - 1 - current;
      const move = this.moves[index] as Move;

      if (previous.time === -1) {
        return move;
      }

      if (direction === undefined) {
        direction = previous.position.x - move.position.x < 0 ? 'left' : 'right';
        return move;
      }

      if (
        (direction === 'left' && previous.position.x - move.position.x < 0) ||
        (direction === 'right' && previous.position.x - move.position.x > 0)
      ) {
        return move;
      }

      return previous;
    }, initial);

    const gap = Math.abs(last.position.x - first.position.x);
    const elapsed = last.time - first.time;

    const offset =
      gap * (gap / elapsed) * ((gap - elapsed) / 100) * (direction === 'left' ? -1 : 1);

    this.emit(Camera.Event.MOVE, offset, Math.abs(offset) / 3, this);
  }

  private getPointerPosition(touch: Touch): Position {
    return {
      x: touch.clientX - (this.element.getBoundingClientRect().left - window.scrollX),
      y: touch.clientY - (this.element.getBoundingClientRect().top - window.scrollY),
    };
  }

  public move(event: TouchEvent): void {
    if (!this.moveInProgress) {
      return;
    }
    const time = window.performance.now();

    const touch = event.changedTouches.item(0);
    if (touch === null) {
      return;
    }

    const previous = this.moves.pop();
    if (previous !== undefined) {
      const position = this.getPointerPosition(touch);
      const gap = position.x - previous.position.x;

      this.moves.push(previous, { position, time });
      this.emit(CameraEvent.MOVE, gap, 15, this);
    }
  }

  public moveCancel(): void {
    this.moveInProgress = false;
    this.moves.splice(0, this.moves.length);
  }

  public moveInit(event: TouchEvent): void {
    if (this.transport.width > this.width) {
      return;
    }

    event.preventDefault();

    const touch = event.touches.item(0);
    if (touch === null) {
      return;
    }
    const time = window.performance.now();

    this.moveInProgress = true;
    this.moves = [{ position: this.getPointerPosition(touch), time }];
  }

  public moveStop(event: TouchEvent): void {
    if (!this.moveInProgress) {
      return;
    }

    event.preventDefault();

    const touch = event.changedTouches.item(0);
    if (touch === null) {
      return;
    }

    this.easeOut();
    this.moveCancel();
  }

  private registerEventListener(): void {
    window.addEventListener('resize', () => this.updateDimension());

    if (this.touchEnabled) {
      this.element.addEventListener('scroll', () => {
        if (this.scrollbar && this.scrollbarHandle) {
          const track = this.scrollbar.offsetWidth - this.scrollbarHandle.offsetWidth;
          const scroll = track * (this.element.scrollLeft / (this.transport.width - this.width));
          this.scrollbarHandle.style.transform = `translateX(${scroll}px)`;
        }
      });
      return;
    }

    this.element.addEventListener(
      'wheel',
      (event) => {
        if (this.transport.width > this.width) {
          this.emit(CameraEvent.MOVE, -1 * event.deltaX + -1 * event.deltaY, false, this);
        }
      },
      { passive: true },
    );

    if (!touchEnabled()) {
      return;
    }

    this.element.addEventListener('touchstart', this.moveInit.bind(this));
    this.element.addEventListener('touchmove', this.move.bind(this));
    this.element.addEventListener('touchend', this.moveStop.bind(this));
  }

  private updateDimension(): void {
    this.height = this.element.offsetHeight;
    this.width = this.element.offsetWidth;

    this.transport.setBounds(this);

    if (this.scrollbar && this.scrollbarHandle) {
      this.scrollbar.style.display = this.width >= this.transport.width ? 'none' : 'block';
      this.scrollbarHandle.style.width = `${100 * (this.width / this.transport.width)}%`;

      const track = this.scrollbar.offsetWidth - this.scrollbarHandle.offsetWidth;
      const scroll = track * (this.element.scrollLeft / (this.transport.width - this.width));
      this.scrollbarHandle.style.transform = `translateX(${scroll}px)`;
    }

    this.emit(CameraEvent.RESIZE, this);
  }

  public getElement(): HTMLElement {
    return this.element;
  }
}
