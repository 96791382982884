import * as Utils from '../utilities';

import { ClientSettings } from '@hello-lisa/schemas';
import { config } from '../../config';
import ky from 'ky';

export const getSettings = async (client: string): Promise<ClientSettings> => {
  return ky
    .get(`${config.settingsUrl}`, Utils.createRequestOptions(client))
    .json<ClientSettings>()
    .catch((err) => {
      console.error(err);
      return {};
    });
};
