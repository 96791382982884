import * as Utils from './utilities';

import { Library } from './library';
import { Section } from './section';
import { Shows } from './api';
import { dateFromDateTime } from '@hello-lisa/utils';
import { uuid } from '@hello-lisa/uuid';

/**
 *
 * @param library - The library instance.
 */
export const autoplay = (library: Library): string | undefined => {
  const parameterName = library.config.player?.autoplay?.parameter ?? 'lisa_autoplay';
  const regex = new RegExp(`${parameterName}=([^&]+)`);
  const match = regex.exec(window.location.search);
  if (match !== null) {
    if (uuid.validate(match[1])) {
      return match[1];
    }
  }

  if (!library.hasShows() || (library.config.autoplay ?? false) === false) {
    return undefined;
  }

  const sources = library.getSources();
  if (library.config.autoplay !== undefined && typeof library.config.autoplay === 'function') {
    try {
      return library.config.autoplay(sources, library);
    } catch (err) {
      console.log('Error in custom autoplay callback', err);
      // silent fail
    }
  }

  sources.sort(Section.sort.bind(null, 1));

  const now = new Date().getTime();
  const upcoming = sources.find((source) => {
    // ignore conducted shows
    if (source.conducted) {
      return false;
    }
    // first show that is currently live
    if (source.live) {
      return true;
    }
    const now = new Date().getTime();
    const showDate = dateFromDateTime(source.date);
    // upcoming shows, that we're scheduled later than within T-1h
    return showDate.getTime() > now - 60 * 60 * 1000;
  });

  const conducted = sources.filter((_) => _.conducted).pop();

  if (upcoming && dateFromDateTime(upcoming.date).getTime() < now + 3 * 60 * 60 * 1000) {
    return upcoming.id;
  }

  if (conducted && dateFromDateTime(conducted.date).getTime() > now - 48 * 60 * 60 * 1000) {
    return conducted.id;
  }

  if (upcoming) {
    return upcoming.id;
  }

  if (conducted) {
    return conducted.id;
  }

  return undefined;
};

export const launchDefaultView = (library: Library): void => {
  library.player.run();
};

export const launchShow = async (
  library: Library,
  event: MouseEvent | string,
  options?: { spotlight?: boolean },
): Promise<void> => {
  const showId =
    typeof event === 'string'
      ? event
      : Utils.closestAncestor(
          event.target as HTMLElement,
          (element: HTMLElement | null) => element?.classList?.contains('lisa__show') ?? false,
        )?.dataset.lisaShowId;

  if (showId === undefined) {
    return;
  }

  let show = library.getSource(showId);
  if (show === undefined) {
    show = await Shows.getShow(library.config.client, showId);
  }

  if (show !== undefined) {
    library.player.run(show, options);
  }
};
