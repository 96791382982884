import { ScrollableResourceList } from '@hello-lisa/schemas';
import { UUID } from '@hello-lisa/uuid';
import { config } from '../../config';
import ky from 'ky';

export const hasSnippets = async (client: string, reference: string): Promise<boolean> => {
  const url = `${config.feedUrl}/v2/snippets/?product_reference=${reference}`;
  return ky
    .head(url, { headers: { Authorization: `Client ${client}` } })
    .then(() => true)
    .catch(() => false);
};

export const getSnippets = async (
  client: string,
  reference: string,
): Promise<{ showId: UUID; startTimeInMs: number }[]> => {
  const url = `${config.feedUrl}/v2/snippets/?product_reference=${reference}`;
  return ky
    .get(url, { headers: { Authorization: `Client ${client}` } })
    .json<ScrollableResourceList<{ showId: UUID; startTimeInMs: number }>>()
    .then((list) => {
      if (list.items.length === 0) {
        return [];
      }
      return list.items.map(({ showId, startTimeInMs }) => ({ showId, startTimeInMs }));
    })
    .catch(() => []);
};
